import * as d3 from 'd3'
import * as svgConverter from '../../../../node_modules/save-svg-as-png'
/**
 * Creates a custom menu component
 * @class
 */
export default class SysMenu {

  #ref;
  #menu;
  #transitionTime;
  #width;
  #closeBtn;
  #rescaleBtn;
  #resetRescaleBtn;
  #rescaleLeftMin;
  #rescaleLeftMax;
  #rescaleRightMin;
  #rescaleRightMax;
  #popup;
  #saveCsvBtn;
  #saveImgBtn;
  #alertInput
  #eventInput;
  #noteInput;
  #radiusInput;
  #strokeInput;
  #externalId;
  #path;
  #minLineWidth;
  #maxLineWidth;
  #minPointWidth;
  #maxPointWidth;
  /**
   * 
   * @param {*} params - allowed params: 
   *  ref {Object} - graph object
   *  transitionTime {Number} - expressed in milliseconds, used to change length in transition effects 
   *  width {Number} - menu width
   */
  constructor(params) {
    this.#ref = params.ref;
    this.#transitionTime = params.transitionTime || 500;
    this.#width = params.width || 250;
    this.#externalId = this.#ref.getExternalId();
    this.#path = this.#ref.getPath();
    this.#minLineWidth = 1;
    this.#maxLineWidth = 5;
    this.#minPointWidth = 1;
    this.#maxPointWidth = 5;


    this.render();

  }


  /**
   * Creates HTML code for menu
   * @returns {String} an HTML string
   */
  createHTML() {

    // note on radio buttons: they need to have a unique name, to avoid conflicts on browser behaviour
    return `
<div id="lineChartMenu" class="sys-lc-menu" style="width: 0px, height 0px">
  <div class="sys-lc-btn-container sys-lc-close-btn">
    <img src="${this.#path}/images/ic_clear_24px_white.svg" class="sys-lc-btn" title="Close">
  </div>
  <div id="lineChartActionMenu">
    <label><img class="sys-lc-menu-icon" src="${this.#path}/images/ic_open_with_24px.svg">Rescale</label>
    <hr>
    <table>
      <tr>
        <td class="column_label">L</td>
        <td>
          Min&nbsp;<input type="number" id="res-l-min_${this.#externalId}" value="0">
          Max&nbsp;<input type="number" id="res-l-max_${this.#externalId}" value="0">
        </td>
        <td></td>
      </tr>
      <tr>
        <td class="column_label">R</td>
        <td>
          Min&nbsp;<input type="number" id="res-r-min_${this.#externalId}" value="0">
          Max&nbsp;<input type="number" id="res-r-max_${this.#externalId}" value="0">
        </td>
        <td></td>
      </tr>
    </table>
    <div>
      <div class="sys-lc-btn-container">
        <img id="btn-rescale" src="${this.#path}/images/ic_fullscreen_24px_white.svg" class="sys-lc-btn" title="Rescale">
        <img id="btn-reset" src="${this.#path}/images/ic_fullscreen_exit_24px_white.svg" class="sys-lc-btn" title="Reset">
      </div>
    </div>

    <label><img class="sys-lc-menu-icon" src="${this.#path}images/ic_file_download_24px.svg">Actions</label>
    <hr>
    <div>
      <div class="sys-lc-btn-container">
      <!--
        <img id="btn-full" src="${this.#path}/images/ic_settings_overscan_24px_white.svg" class="sys-lc-btn" title="View in fullscreen">
      -->  
        <img id="btn-csv" src="${this.#path}/images/ic_description_24px_white.svg" class="sys-lc-btn" title="Export data in csv">
        <img id="btn-image" src="${this.#path}/images/ic_image_24px_white.svg" class="sys-lc-btn" title="Save image">
      </div>
    </div>


    <label><img class="sys-lc-menu-icon" src="${this.#path}/images/ic_settings_24px.svg">Configuration</label>
    <hr>
    <div>
      <table>
        <tr>
          <td class="column_label">Toggle alerts</td>
          <td>
            ON&nbsp;<input type="radio" name="slc_${this.#externalId}_alrt-tog" value="1">
            OFF&nbsp;<input type="radio" name="slc_${this.#externalId}_alrt-tog" value="0">
          </td>
        </tr>
        <tr>
          <td class="column_label">Toggle events</td>
          <td>
            ON&nbsp;<input type="radio" name="slc_${this.#externalId}_evnt-tog" value="1" >
            OFF&nbsp;<input type="radio" name="slc_${this.#externalId}_evnt-tog" value="0">
          </td>
        </tr>
        <tr>
          <td class="column_label">Toggle notes</td>
          <td>
            ON&nbsp;<input type="radio" name="slc_${this.#externalId}_note-tog"value="1" >
            OFF&nbsp;<input type="radio" name="slc_${this.#externalId}_note-tog" value="0">
          </td>
        </tr>
        <tr>
          <td class="column_label">Point radius</td>
          <td>
            <input type="number" value="3" id="pnt-rad_${this.#externalId}" min="${this.#minPointWidth}" max="${this.#maxPointWidth}">
          </td>
        </tr>
        <tr>
          <td class="column_label">Line stroke</td>
          <td>
            <input type="number" value="2" id="line-strk_${this.#externalId}" min="${this.#minLineWidth}" max="${this.#maxLineWidth}">
          </td>
        </tr>
      </table>
    </div>

  </div>
</div>
    `;

  }

  /**
   * Renders menu
   */
  render() {

    let that = this;
    let target = d3.select(`#${this.#ref.getTarget()}`);
    let menuBtn = target.select('.sys-lc-menu-btn');
    let menuContainer = target.select('#sys-menu');

    // create open menu button
    if (menuBtn.size() === 0) {
      target
        .append("div")
        .attr("class", "sys-lc-menu-btn")
        .attr("title", "Options menu")
        .style("top", `${target.nodes()[0].offsetTop + 42}px`)
        .style("left", `${target.nodes()[0].offsetLeft + 2}px`)
        .append("img")
        .attr("src", `${this.#path}/images/ic_menu_24px_white.svg`)
        .attr("class", "sys-lc-btn")
        .on("click", () => {
          that.open.call(that);
        });
    }

    // create menu container
    if (menuContainer.size() === 0) {
      menuContainer = target.append("div").attr("id", "sys-menu");
    }

    // update html content
    menuContainer.html(this.createHTML());

    // set menu position
    this.#menu = target.select('#lineChartMenu')
      .style("height", `${this.#ref.outerHeight}px`)
      .style("width", "0px")
      .style("top", `${target.nodes()[0].offsetTop}px`)
      .style("left", `${target.nodes()[0].offsetLeft}px`)
      .style("opacity", 0)

    // set close menu button
    this.#closeBtn = this.#menu.select('img[title="Close"]').on("click", () => {
      that.close.call(that);
    });

    // set events on buttons
    // RESCALE
    this.#rescaleLeftMin = this.#menu.select(`#res-l-min_${this.#externalId}`);
    this.#rescaleLeftMax = this.#menu.select(`#res-l-max_${this.#externalId}`);
    this.#rescaleRightMin = this.#menu.select(`#res-r-min_${this.#externalId}`);
    this.#rescaleRightMax = this.#menu.select(`#res-r-max_${this.#externalId}`);

    this.#rescaleBtn = this.#menu.select('#btn-rescale').on("click", () => {

      const that = this;

      let lMin = that.#rescaleLeftMin.property("value");
      let lMax = that.#rescaleLeftMax.property("value");
      let rMin = that.#rescaleRightMin.property("value");
      let rMax = that.#rescaleRightMax.property("value");

      if (!isNaN(lMin) && !isNaN(lMax)) {
        that.#ref.handleRescale("RESCALE", "LEFT", lMin, lMax);
      }

      if (that.#ref.getAxisMode() === 3 && !isNaN(rMin) && !isNaN(rMax)) {
        that.#ref.handleRescale("RESCALE", "RIGHT", rMin, rMax);
      }

    });

    // RESET RESCALE
    this.#resetRescaleBtn = this.#menu.select('#btn-reset').on("click", () => {

      that.#ref.handleRescale("RESET", "LEFT");

      if (that.#ref.getAxisMode() === 3) {
        that.#ref.handleRescale("RESET", "RIGHT");
      }

    });

    //OPEN NEW GRAPH IN POPUP
    /*
    this.#popup = this.#menu.select('#btn-full').on("click", (e) => {
      e.stopPropagation();
      const popup = that.#ref.getPopup();
      popup.open();
      // render a copy of the current graph
      popup.renderGraph();
      // close immediately menu
      this.close();
    });
    */

    // SAVE CSV
    this.#saveCsvBtn = this.#menu.select('#btn-csv').on("click", (e) => {
      e.stopPropagation();
      that.#ref.downloadFile(that.#ref.createCsv(), "export_data");
    });

    // SAVE IMAGE
    this.#saveImgBtn = this.#menu.select('#btn-image').on("click", (e) => {
      e.stopPropagation();
      svgConverter.saveSvgAsPng(document.getElementById(`slc_${that.#ref.getExternalId()}_SVG`), "diagram.png");
    });

    // TOGGLE ALERTS
    this.#alertInput = this.#menu.selectAll(`input[name=slc_${this.#externalId}_alrt-tog]`).on("change", (e) => {
      that.#ref.handleAlertToggle(e.target.value);
    });

    // TOGGLE EVENTS
    this.#eventInput = this.#menu.selectAll(`input[name=slc_${this.#externalId}_evnt-tog]`).on("change", (e) => {
      that.#ref.handleEventToggle(e.target.value);
    });

    // TOGGLE NOTES
    this.#noteInput = this.#menu.selectAll(`input[name=slc_${this.#externalId}_note-tog]`).on("change", (e) => {
      that.#ref.handleNoteToggle(e.target.value);
    });

    //POINT RADIUS
    this.#radiusInput = this.#menu.select(`#pnt-rad_${this.#externalId}`).on("change", (e) => {
      
      let _val = e.target.value;
      if(_val < this.#minPointWidth) _val = this.#minPointWidth;
      if(_val > this.#maxPointWidth) _val = this.#maxPointWidth;

      that.#ref.handlePointRadius(_val);
    });

    //LINE STROKE
    this.#strokeInput = this.#menu.select(`#line-strk_${this.#externalId}`).on("change", (e) => {
      
      let _val = e.target.value;
      if(_val < this.#minLineWidth) _val = this.#minLineWidth;
      if(_val > this.#maxLineWidth) _val = this.#maxLineWidth; 
      
      that.#ref.handleLineStroke(_val);
    });

  }

  /**
   * Sets right value in rescale fields
   * @param {String} axis - which field to update. Allowed values : LEFT, RIGHT 
   * @param {Number} min - min value 
   * @param {Number} max - max value
   */
  setRescaleFields(axis, min, max) {

    switch (axis) {
      case 'LEFT':
        this.#rescaleLeftMin.property("value", min);
        this.#rescaleLeftMax.property("value", max);
        break;
      case 'RIGHT':
        this.#rescaleRightMin.property("value", min);
        this.#rescaleRightMax.property("value", max);
        break;
    }

  }

  /**
   * Opens the menu
   */
  open() {
    this.#menu.transition().duration(this.#transitionTime).style("opacity", 0.9).style("width", `${this.#width}px`);
  }

  /**
   * Closes the menu
   */
  close() {
    this.#menu.transition().duration(this.#transitionTime).style("opacity", 0).style("width", "0px");
  }

  /**
   * Updates configurations params on menu
   * @param {Array} data 
   */
  updateConfigParams(data) {

    if (data.rescale.left.min !== undefined && data.rescale.left.min !== null) {
      this.#rescaleLeftMin.property("value", data.rescale.left.min);
      this.#rescaleLeftMax.property("value", data.rescale.left.max);
      this.#rescaleRightMin.property("value", data.rescale.right.min);
      this.#rescaleRightMax.property("value", data.rescale.right.max);
    }

    if (data.opacity.alert !== null && data.opacity.alert !== undefined) {
      const nodes = this.#alertInput.nodes()
      data.opacity.alert * 1 === 0 ? d3.select(nodes[1]).property("checked", true) : d3.select(nodes[0]).property("checked", true);
      this.#ref.alertToggleVisibility(data.opacity.alert);
    }

    if (data.opacity.event !== null && data.opacity.event !== undefined) {
      const nodes = this.#eventInput.nodes()
      data.opacity.event * 1 === 0 ? d3.select(nodes[1]).property("checked", true) : d3.select(nodes[0]).property("checked", true);
      this.#ref.eventToggleVisibility(data.opacity.event);
    }

    if (data.opacity.note !== null && data.opacity.note !== undefined) {
      const nodes = this.#noteInput.nodes()
      data.opacity.note * 1 === 0 ? d3.select(nodes[1]).property("checked", true) : d3.select(nodes[0]).property("checked", true);
      this.#ref.noteToggleVisibility(data.opacity.note);
    }

    if (data.pointRadius !== null) {
      this.#radiusInput.property("value", data.pointRadius);
      this.#ref.updatePointRadius(data.pointRadius);
    }

    if (data.lineStroke !== null) {
      this.#strokeInput.property("value", data.lineStroke);
      this.#ref.updateLineStroke(data.lineStroke);
    }

  }

}